<template>
    <div>
        <el-row>
            <el-col :span="24">
               <div class="title">提现订单</div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-row>
                    <el-col :span="20">
                        <div class="query">
                            <div class="content">
                                订单编号：
                                <el-input
                                    type="text"
                                    placeholder="输入订单编号"
                                    v-model="query.tcNo"
                                    style="width: 200px !important;">
                                </el-input>
                                &nbsp;
                                订单状态：
                                <el-select v-model="query.status">
                                    <el-option label="请选择" :value="-1"></el-option>
                                    <el-option label="待审核" :value="0"></el-option>
                                    <el-option label="待结算" :value="1"></el-option>
                                    <el-option label="已结算" :value="2"></el-option>
                                    <el-option label="审核失败" :value="3"></el-option>
                                    <el-option label="结算失败" :value="4"></el-option>
                                </el-select>
                                &nbsp;
                                推广人码：
                                <el-input
                                    type="text"
                                    placeholder="输入推广人的推广码"
                                    v-model="query.father"
                                    maxlength="11"
                                    style="width: 150px !important;">
                                </el-input>
                                <el-button type="success" @click="initorderList(1)">查询</el-button>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div style="margin-bottom: 20px; text-align: right;">
                            <!-- <el-button type="primary" @click="toAdd">添加套餐</el-button> -->
                        </div>
                    </el-col>
                </el-row>
                    <el-table
                        ref="tcorderTable"
                        :data="tcOrderList.data"
                        tooltip-effect="dark"
                        border
                        stripe
                        height="600">
                        <el-table-column
                            v-if="isShowIdColumn"
                            prop="id"
                            label="订单ID"
                            >
                        </el-table-column>
                        <el-table-column
                            prop="tcNo"
                            label="订单编号"
                            width="200">
                        </el-table-column>
                        <el-table-column
                            label="提成金额"
                            prop="tcMoney">
                        <template slot-scope="scope">
                            <el-tag
                            type="success"
                            style="font-weight: 600; font-size:18px;"
                            disable-transitions>￥{{scope.row.tcMoney}}</el-tag>
                        </template>
                        </el-table-column>
                        <el-table-column
                            prop="applyTime"
                            label="申请日期">
                        </el-table-column>
                        <el-table-column
                            prop="checkTime"
                            label="审核日期">
                        </el-table-column>
                        <el-table-column
                            prop="acceptTime"
                            label="结算日期">
                        </el-table-column>
                        <el-table-column
                            prop="tcRemark"
                            label="说明"
                            show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column
                            prop="statusName"
                            label="订单状态">
                            <template slot-scope="scope">
                                <el-tag
                                :type="scope.row.status === 0 ? '' : 
                                scope.row.status === 1 ? 'warning' : 
                                scope.row.status === 2 ? 'success' : 
                                scope.row.status === 3 ? 'danger' : 
                                scope.row.status === 4 ? 'danger' : 'info'"
                                disable-transitions>{{scope.row.statusName}}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="linkOrder"
                            label="关联订单"
                            show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column
                            fixed="right"
                            label="操作"
                            width="100">
                            <template slot-scope="scope">
                                <el-button type="primary" size="small" @click="handleClick_Check(scope.row)">审核</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :total="tcOrderList.count"
                        :page-size="pageSize"
                        @current-change="initTGOrderList">
                    </el-pagination>
            </el-col>
        </el-row>
        
        <!-- Dialog组件 -->
        <el-drawer class="my-custom-dialog" :visible.sync="dialogpackAdd" :title="form.title" size="50%" direction="rtl" @close="closeDialog">
            <!-- 引入Vue文件 -->  
            <el-row>
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="22">
                    <el-card>
                        <el-form ref="packForm" :model="form" label-width="80px" label-position="left">
                            <el-form-item label="订单号">
                                <el-input v-model="form.tcNo" disabled></el-input>
                            </el-form-item>
                            <el-form-item label="提现金额">
                                <el-input type="number" v-model="form.tcMoney" disabled>
                                    <template slot="append">元</template>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="申请日期">
                                <el-input v-model="form.applyTime" disabled></el-input>
                            </el-form-item>
                            <el-form-item label="订单状态">
                                <el-input v-model="form.statusName" disabled></el-input>
                            </el-form-item>
                            <el-form-item label="关联订单">
                                <el-table
                                    ref="OrderTable"
                                    :data="orderList.data"
                                    tooltip-effect="dark"
                                    border
                                    stripe
                                    :summary-method="getSummaries"
                                    show-summary
                                    height="300">
                                    <el-table-column
                                        v-if="isShowIdColumn"
                                        prop="id"
                                        label="订单ID"
                                        >
                                    </el-table-column>
                                    <el-table-column
                                        prop="tradeNo"
                                        label="订单编号"
                                        width="200">
                                    </el-table-column>
                                    <el-table-column
                                        label="订单金额"
                                        sortable
                                        prop="orderMoney">
                                    </el-table-column>
                                    <el-table-column
                                        prop="tcLV"
                                        label="提成点数"
                                        show-overflow-tooltip>
                                        <template slot-scope="scope">{{ scope.row.tcLV*100 }}%</template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="tcMoney"
                                        label="可提成金额"
                                        show-overflow-tooltip>
                                    </el-table-column>
                                    <el-table-column
                                        prop="tradeStatus"
                                        sortable
                                        label="支付状态">
                                        <template slot-scope="scope">
                                            <el-tag
                                            :type="scope.row.tradeStatus === '成功' ? 'success' : 'info'"
                                            disable-transitions>{{scope.row.tradeStatus}}</el-tag>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="tradeType"
                                        sortable
                                        label="支付方式">
                                        <template slot-scope="scope">
                                            <el-tag
                                            :type="scope.row.tradeType === '支付宝' ? 'primary' : 'success'"
                                            disable-transitions>{{scope.row.tradeType}}</el-tag>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="createTime"
                                        sortable
                                        label="订单日期">
                                    </el-table-column>
                                    <el-table-column
                                        prop="tradeTime"
                                        sortable
                                        label="支付日期">
                                    </el-table-column>
                                    <el-table-column
                                        v-if="isShowIdColumn"
                                        prop="nickName"
                                        sortable
                                        label="用户昵称"
                                        show-overflow-tooltip>
                                    </el-table-column>
                                    <el-table-column
                                        v-if="isShowIdColumn"
                                        prop="tcName"
                                        sortable
                                        label="套餐名称"
                                        show-overflow-tooltip>
                                    </el-table-column>
                                    <el-table-column
                                        v-if="isShowIdColumn"
                                        prop="tcStatus"
                                        sortable
                                        label="提成状态"
                                        show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <el-tag
                                            :type="scope.row.tcStatus === 0 ? 'success' : 'danger'"
                                            disable-transitions>{{scope.row.tcStatus===0?'未锁定结算':'锁定结算中'}}</el-tag>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-form-item>
                            <el-form-item label="审核状态" style="text-align:left;">
                                <el-radio-group v-model="form.status" :disabled="form.isCheck" @change="handleRaido_Change">
                                    <el-radio :label="1" border>同意提现</el-radio>
                                    <el-radio :label="3" border>不同意提现</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="审核意见">
                                <el-input type="textarea" 
                                maxlength="100"
                                show-word-limit
                                v-model="form.tcRemark"></el-input>
                            </el-form-item>
                            <el-form-item style="text-align: center;">
                                <el-button type="primary" @click="onSubmit">保存</el-button>
                                <el-button @click="closeDialog">取消</el-button>
                            </el-form-item>
                        </el-form>
                    </el-card>
                </el-col>
                <el-col :span="1"></el-col>
            </el-row>
        </el-drawer>
    </div>
</template>

<style>
.tbHeader,.tbHeader th{
    background-color: #fafafa !important;
    color:#333;
    font-weight: 500;
}
.title{
    font-size: 20px;
    text-align: left; 
    /* text-indent: 2em; 
    background: #f5f7fa75;  */
    height: 30px; 
    line-height: 30px;
    margin-bottom: 20px;
}
.query{
    margin-bottom: 20px; 
    text-align: left;
}
/* .query .el-input{
    width:150px ;
} */
.query .content .el-button{
    margin-left:10px;
}
</style>

<script>
// import cookieH from 'js-cookie'
import axios from 'axios'
import $conf from '@/apiconfig'

    export default {
        name: 'taocanManage',
        data() {
            return {
                dialogpackAdd:false,
                isShowIdColumn:false,
                query:{
                    tcNo:'',
                    status:-1,
                    father:''
                },
                qiuhe:[
                    "orderMoney",
                    "tcMoney",
                ],
                pageSize:10,
                tcOrderList:[],
                orderList:[],

                form:{
                    id:0,
                    tcNo:'',
                    tcMoney:0,
                    status:0,
                    statusName:'',
                    tcRemark:'',
                    tcRemarktemp:'',
                    linkOrder:[],
                    applyTime:'',
                    checkTime:'',
                    acceptTime:'',
                    isCheck:false,
                    title:''
                },
            };
        },
        mounted(){
            this.init();
        },
        beforeDestroy(){
        },
        methods:{
            init:function(){
                this.initTGOrderList(1)
            },
            closeDialog(){
                this.form.id=0
                this.form.tcNo=''
                this.form.tcMoney=0
                this.form.status=0
                this.form.statusName=''
                this.form.tcRemark=''
                this.form.tcRemarktemp=''
                this.form.linkOrder=''
                this.form.applyTime=''
                this.form.checkTime=''
                this.form.acceptTime=''
                this.form.title=''
                this.dialogpackAdd=false
            },
            greCurrentStamp(){
                let timestamp = Date.now();
                return timestamp;
            },
            async initTGOrderList(pageIndex){
                try {
                    
                    pageIndex = (pageIndex==undefined || pageIndex == 0) ? 1 : pageIndex
                    const loginUrl=$conf.dev.apiUrl+'tgCenter/query/tcorderlist';
                    const response = await axios.post(loginUrl, {
                        tcNo:this.query.tcNo,
                        status:this.query.status,
                        father:this.query.father,
                        pageSize:this.pageSize,
                        pageIndex:pageIndex
                    },{
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        resD.data.forEach(order => {
                            order.applyTime = $conf.common.formattedDate(order.applyTime);
                            order.checkTime = $conf.common.formattedDate(order.checkTime);
                            order.acceptTime = $conf.common.formattedDate(order.acceptTime);
                            order.statusName=order.status==0?'待审核':
                                        order.status==1?'未结算':
                                        order.status==2?'已结算':
                                        order.status==3?'审核失败':
                                        order.status==4?'结算失败':'未知';
                        });
                        this.tcOrderList=resD;
                    }else{
                        this.$message.error(resD.msg);  
                    }
                } catch (error) {  
                    this.$message.error(error);  
                } 
            },
            async initorderList(pageIndex){
                try {
                    pageIndex = (pageIndex==undefined || pageIndex == 0) ? 1 : pageIndex
                    const loginUrl=$conf.dev.apiUrl+'admin/order/list';
                    const response = await axios.post(loginUrl, {
                        tradeNo:'',
                        ids:this.form.linkOrder,
                        tradeStatus:1,
                        father:'',
                        pageSize:100,
                        pageIndex:pageIndex
                    },{
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        resD.data.forEach(order => {
                            order.createTime = $conf.common.formattedDate(order.createTime);
                            order.tradeTime = $conf.common.formattedDate(order.tradeTime);
                            order.tradeType=order.tradeType==1?'微信':order.tradeType==2?'支付宝':'未知';
                            order.tradeStatus=order.tradeStatus==1?'成功':order.tradeStatus==2?'失败':'未支付';
                            order.isSelect = order.tcStatus==0;
                        });
                        this.orderList=resD;
                    }else{
                        this.$message.error(resD.msg);  
                    }
                } catch (error) {  
                    this.$message.error(error);  
                } 
            },
            getSummaries(param){
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    const values = data.map(item => Number(item[column.property]));
                    if (this.qiuhe.includes(column.property)) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                        sums[index] = sums[index].toFixed(2) + ' 元';
                    } else {
                        sums[index] = '';
                    }
                });

                return sums;
            },
            handleSelectionChange(){

                
            },
            handleRaido_Change(value){
                if(value==1){
                    this.form.tcRemark=this.form.tcRemarktemp+"=>同意提现[管理员]"
                }else{
                    this.form.tcRemark=this.form.tcRemarktemp+"=>不同意提现[管理员]"
                }
            },
            handleDateChange(value){
                this.form.expressTime = $conf.common.formatTimeStamp(value)/1000
            },
            handleClick_Check(row){

                this.dialogpackAdd=true
                this.form.id=row.id
                this.form.tcNo=row.tcNo
                this.form.tcMoney=row.tcMoney
                this.form.status=row.status
                this.form.statusName=row.statusName
                this.form.tcRemark=row.tcRemark
                this.form.tcRemarktemp=row.tcRemark
                this.form.linkOrder=[]
                JSON.parse(row.linkOrder).forEach(item=>{
                    this.form.linkOrder.push(item)
                })
                // this.form.linkOrder=row.linkOrder
                this.form.applyTime=row.applyTime
                this.form.checkTime=row.checkTime
                this.form.acceptTime=row.acceptTime
                this.form.title='提现审核'
                if(this.form.status!=0){
                    this.form.isCheck=true
                }
                this.initorderList(1)
                console.log(row);

            },
            async onSubmit(){
                console.log(this.form)
                let vm = this
                try {
                    const loginUrl=$conf.dev.apiUrl+'admin/tixian/save';
                    const response = await axios.post(loginUrl, {
                        id:this.form.id,
                        tcName:this.form.tcName,
                        status:this.form.status,
                        amount:this.form.amount,
                        amountYH:this.form.amountYH,
                        endDays:this.form.endDays,
                    },{
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        this.$message.success({
                            message:resD.data,
                            duration:1000,
                            onClose:function(){
                                vm.initorderList(1)
                                vm.closeDialog()
                            }
                        })
                    }else{
                        this.$message.error(resD.msg);  
                    }
                } catch (error) {  
                    this.$message.error(error);  
                } 
            }
        }
    }
</script>