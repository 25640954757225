<template>
    <el-container height="100%" width="100%">
        <el-main ref="elmain" id="elmain">
            <div class="div_init" v-if="(tid=='0' || tid=='') && topic_code==0">
                <div class="div_init_t1">
                    有什么可以帮助您的？
                </div>
                <div class="div_init_t2">
                    作为你的智能伙伴，我既能写文案、想点子，又能陪你聊天、答疑解惑。<br>
                    想知道我还能做什么？可以浏览器收藏[<span style="color: dodgerblue;"> 启鹏Ai </span>]官网地址，下次使用更高效哦～
                </div>
            </div>
            <div ref="div_scroll" id="div_scroll" style="overflow-y: auto; "  v-else>
                <div v-for="(chatone,index) in chatList" :key="chatone.id">
                    <el-row :class="{  
                            'row-h': true,  
                            'row-h-first': index === 0,  
                        }">
                        <el-col :span="3" style="text-align: right; padding-right: 10px;">
                            <el-button v-if="chatone.role == 'user'" type="warning" icon="el-icon-s-custom" circle></el-button>
                            <el-button v-else type="primary" icon="el-icon-user-solid" circle></el-button>
                        </el-col>
                        <el-col :span="20" style="text-align: left; padding-left: 10px;">
                            <el-row>
                                <el-col :span="24" class="el-col-title">{{chatone.role == 'user'?'You':'启鹏Ai'}}</el-col>
                                <el-col :span="24" :class="{
                                    'col-content':true,
                                    'aiContent':chatone.role == 'user'
                                }"><vue-markdown :source="chatone.content"  :breaks="true"/>
                            <el-button type="text" v-if="chatone.role != 'user'"
                                    @click="copyText(chatone.content)" 
                                    class="copyBtn"
                                    icon="el-icon-copy-document">复制</el-button>    
                            </el-col>
                                <el-col :span="24" v-if="index==chatList.length-1 && chatone.role == 'user' && messCont==''" class="col-rechat">
                                    <el-button type="text" @click="reSubmit(chatone.paramToken)">重新生成</el-button>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="1"></el-col>
                    </el-row>
                </div>
                <div>
                    <el-row>
                        <el-col :span="24" style="display: none;">
                            <vue-markdown :source="copyContent" :breaks="true" ref="copyVue"/>
                        </el-col>
                    </el-row>
                    <el-row class="row-h" v-if="messCont!=''">
                        <el-col :span="3" style="text-align: right; padding-right: 10px;">
                            <el-button type="primary" icon="el-icon-user-solid" circle></el-button>
                        </el-col>
                        <el-col :span="20" style="text-align: left; padding-left: 10px;">
                            <el-row>
                                <el-col :span="24" class="el-col-title">启鹏Ai</el-col>
                                <el-col :span="24" class="col-content"><vue-markdown :source="messCont"  :breaks="true"/></el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="1"></el-col>
                    </el-row>
                </div>
               
            </div>
            
        </el-main>
        <el-footer height="120px" style="padding: 0px 10px 0px 10px;">
            <el-row>
                <el-col :span="2"></el-col>
                <el-col :span="20">
                    <el-input 
                    type="textarea" 
                    placeholder="请说出你的问题" 
                    :rows="3" 
                    :maxlength="1000" 
                    :show-word-limit="true" 
                    v-model="content"
                    @keydown="handleKeyPress"></el-input>
                </el-col>
                <el-col :span="2" style="text-align: center;  padding-top: 30px;">
                    <el-button type="primary" 
                    :icon="iconClass" 
                    :disabled="isDisabledButton" circle @click="onSubmit"></el-button>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="2"></el-col>
                <el-col :span="20" style="text-align: right;">最多1000字</el-col>
                <el-col :span="2"></el-col>
            </el-row>
        </el-footer>
    </el-container>
</template>

<script>
import cookieH from 'js-cookie'
import axios from 'axios'
import $conf from '@/apiconfig'
import VueMarkdown from 'vue-markdown';

    export default {
        name: 'onceChat',
        props:{
            tid: {
                type: [String, Number],
                default: 0,
            },
        },
        components: {
            VueMarkdown,
        },
        data()
        {
            return {
                inx:0,
                LGOIN_CODE: 0,  
                content:"",
                chatList:[],
                // eventSource:null,
                messCont:'',
                paramToken:'', 
                topic_code:0,
                isDisabledButton:false,
                copyContent:''
            };
        }, 
        // beforeDestroy() {  
        //     if (this.eventSource) {  
        //         this.eventSource.close(); // 在组件销毁前关闭连接  
        //     }  
        // },
        watch:{
            tid(){
                // this.$message.info("init     "  + this.tid)
                if(this.tid!=0){
                    this.chatList={};
                    this.init();
                }
            },
            chatList(){
                console.log('拉一下')
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        // 使用 $refs 访问 DOM 元素  
                        // console.log('Before setting scrollHeight:', this.$refs.div_scroll.scrollHeight);
                        // console.log('Before setting scrollTop:', this.$refs.elmain.scrollTop);
                        // console.log('Before setting clientHeight:', this.$refs.div_scroll.clientHeight); // 容器的高度

                        // 有滚动特效的
                        // const target = this.$refs.div_scroll;
                        // target.scrollIntoView({ behavior: 'smooth', block: 'end' });

                        // 直接入底，无滚动特效
                        this.$refs.div_scroll.scrollTop = this.$refs.div_scroll.scrollHeight;
                        this.$refs.elmain.scrollTop = this.$refs.div_scroll.scrollHeight;
                        setTimeout(() => {
                            document.getElementById('elmain').scrollTop = this.$refs.div_scroll.scrollHeight;
                        }, 1);
                        
                        // console.log('After setting scrollHeight:', this.$refs.div_scroll.scrollHeight);
                        // console.log('After setting scrollTop:', this.$refs.elmain.scrollTop);
                        // console.log('After setting clientHeight:', this.$refs.div_scroll.clientHeight); // 容器的高度
                    }); 
                }); 
            },
            messCont(){
                this.$nextTick(() => {
                    // 使用 $refs 访问 DOM 元素  
                    // 有滚动特效的
                    // const target = this.$refs.div_scroll;
                    // target.scrollIntoView({ behavior: 'smooth', block: 'end' });

                    // 直接入底，无滚动特效
                    this.$refs.div_scroll.scrollTop = this.$refs.div_scroll.scrollHeight;
                    this.$refs.elmain.scrollTop = this.$refs.div_scroll.scrollHeight;
                    setTimeout(() => {
                        document.getElementById('elmain').scrollTop = this.$refs.div_scroll.scrollHeight;
                    }, 1);
                }); 

            }
        },  
        computed: {  
            iconClass() {  
                return this.isDisabledButton ? 'el-icon-loading' : 'el-icon-s-promotion';  
            },  
        },
        methods:{
            handleKeyPress(event){
                // 检查是否按下的是 Enter 键
                console.log('1')
                if (event.key === 'Enter') {
                    console.log('2')
                    event.preventDefault(); // 阻止默认行为
                    // 如果同时按下了 Shift 键，则插入换行符
                    if (event.shiftKey) {
                        console.log('3')
                        this.content += '\n'; // 插入换行符
                    } else {
                        // 如果没有按 Shift 键，则提交表单
                        this.onSubmit();
                    }
                }
            },
            init: async function(){
                const token = cookieH.get("userTK");
                if(token == "" || token == undefined){
                    this.$message.error("您还没有登陆，请先登录");
                    this.$router.push('/');
                }else{
                    try {
                        const loginUrl=$conf.dev.apiUrl+'chat/list';
                        const response = await axios.post(loginUrl, {
                            token:token,
                            tid:this.tid
                        },{
                            headers: {
                                "Content-Type": "application/json"
                            }
                        });  
                        const resD = response.data;
                        if(resD.code==0){
                            resD.data.forEach(chat => {
                                chat.content = chat.content.replace(/\\n/g, '<br>');
                            });
                            this.chatList=resD.data;
                        }else{
                            this.$message.error(resD.msg);  
                        }
                    } catch (error) {  
                        this.$message.error(error);  
                    } 
                }
                
            },
            routeHttp:function(){
                if(this.tid==0 && this.topic_code>0){
                    if(this.getDeviceType()=="mobile"){
                        this.$router.replace('/mobileChat/'+this.topic_code)
                    }else{
                        this.$router.replace('/chatMain/'+this.topic_code)
                    }
                }
            },
            onChatStream: async function(){
                const vam = this;
                if(vam.paramToken==""){
                    this.$message.error("网络错误，请稍后重试……")
                    this.isDisabledButton = false;
                    return;
                }
                const streamUrl=$conf.dev.apiUrl+'chat/stream?paramToken='+vam.paramToken;

                fetch(streamUrl, {  
                    method: 'GET',  
                    headers: {  
                        // 可能需要设置适当的 headers  
                    }
                })  
                .then(response => {  
                    if (!response.ok) {  
                        throw new Error('Failed to fetch data');  
                    }  
                    return response.body.getReader();  
                })  
                .then(reader => {  
                    function pump() {  
                        return reader.read().then(({ done, value }) => {  
                            if (done) { 
                                console.log("流结束") 
                                vam.chatList.push({id:vam.inx,uid:8,topic:1,role:"assistant",content:vam.messCont});
                                vam.inx--;
                                vam.paramToken=''
                                vam.messCont=''
                                vam.routeHttp();
                                vam.isDisabledButton = false;
                                return; // 流结束  
                            }  
                            // value 是一个 Uint8Array，需要转换为文本  
                            let text = new TextDecoder("utf-8").decode(value);  
                            // 处理文本，这里假设每个消息都是完整的并且用换行符分隔  
                            // this.messages = text;//.split('\n'); // 根据你的实际分隔符进行修改  
                            vam.messCont+=text;
                            // console.log(text)
                            // 继续读取流  
                            return pump();  
                        });  
                    }  
                    return pump(); // 开始读取流  
                })  
                .catch(error => {  
                    console.error('There has been a problem with your fetch operation:', error);  
                    vam.isDisabledButton = false;
                });
            },
            onSubmit:async function(){
                this.isDisabledButton = true;
                const token = cookieH.get("userTK");
                if(this.content==""){
                    this.$message.error("您还没有问呢……");
                    this.isDisabledButton = false;
                    return;
                }
                if(token == "" || token == undefined){
                    this.$message.error("您还没有登陆，请先登录");
                    this.isDisabledButton = false;
                    this.$router.replace('/');
                    return;
                }
                
                try {
                    const loginUrl=$conf.dev.apiUrl+'chat/chatStream';
                    const response = await axios.post(loginUrl, {
                        token:token,
                        tid:this.tid,
                        content:this.content
                    },{
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        this.paramToken=resD.data;
                        this.topic_code=resD.msg;
                        this.chatList.push({id:this.inx,uid:8,topic:this.topic_code,role:"user",content:this.content});
                        this.inx--;
                        this.messCont='';
                        this.content='';
                        this.onChatStream();
                    }else{
                        this.$message.error(resD.msg);  
                        this.isDisabledButton = false;
                    }
                } catch (error) {  
                    this.$message.error(error); 
                    this.isDisabledButton = false;
                } 
                



                /*

                // 创建一个新的 EventSource 实例并连接到后端 SSE 端点
                this.eventSource = new EventSource('http://localhost:8085/demo/stream');

                // 监听 'message' 事件，当服务器发送新消息时触发  
                this.eventSource.onmessage = event => {  
                    // event.data 包含服务器发送的消息  
                    console.log(event.data);
                    this.messages.push(event.data);  
                };  
            
                // 监听 'error' 事件，当连接错误时触发  
                this.eventSource.onerror = error => {  
                    console.log('SSE Error:', error);  
                    this.eventSource.close();
                };  
            
                // 监听 'open' 事件，当连接打开时触发  
                this.eventSource.onopen = event => {  
                    console.log('SSE Connected:', event);  
                };  
            
                // 监听 'close' 事件，当连接关闭时触发  
                this.eventSource.onclose = event => {  
                    console.log('SSE Closed:', event);  
                };  
                
                 */



                // this.chatList.push({id:0,uid:8,topic:1,role:"user",content:this.content.trim()});
                
                // if (this.content.trim() === '') {
                //     return;
                // }
                // const token = cookieH.get("userTK");
                // if(token == "" || token == undefined){
                //     this.$message.error("您还没有登陆，请先登录");
                //     this.$router.push('/');
                // }else{
                //     try {
                //         const apiUrl=$conf.dev.apiUrl+'chat/chatOnce';
                //         const response = await axios.post(apiUrl, { 
                //             content: this.content,
                //             token:token,
                //             tid:this.tid 
                //         });
                //         const resD = response.data;
                //         if(resD.code==0){
                //             this.chatList.push({id:0,uid:8,topic:1,role:"assistant",content:resD.data});
                //         }else{
                //             this.$message.error(resD.msg);  
                //         }
                //         // this.responses.push({ id: Date.now(), content: response.data.message });

                //         this.content = '';
                //     } catch (error) {
                //         console.error('Error sending message:', error);
                //     }
                // }
            },
            reSubmit:async function(paramToken){
                this.paramToken=paramToken;
                this.onChatStream();
            },
            async copyText(content){
                
                this.copyContent = content;
                // 等待下一次 DOM 更新  
                await this.$nextTick();
                // 获取 vue-markdown 组件渲染后的 HTML  
                const markdownElement = this.$refs.copyVue.$el;  
                // 获取要复制的文本（这里我们假设是整个元素的 innerHTML）  
                const textToCopy = markdownElement.innerText || markdownElement.textContent;  
            
                try {  
                    await navigator.clipboard.writeText(textToCopy);   
                    // const msg = successful ? '复制成功' : '复制失败';  
                    this.$message.success('复制成功')
                } catch (err) {  
                    this.$message.error('复制失败')
                }  finally{
                    this.copyContent=''
                }
            },
            getDeviceType() {  
                const userAgent = navigator.userAgent;  
                
                // 匹配常见的移动设备 User-Agent  
                const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);  
                
                return isMobile ? 'mobile' : 'desktop';  
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-container{
height: 100%;
overflow: hidden;
    color: #000;

}
.el-footer {
    background-color: #ffffff;
    color: #000;
    text-align: center;
    height: 120px;
    border-top: 1px solid #f4f4f4;
}
.copyBtn{
    float:right;
}
.div_init{
   margin-top: 20vh;
}
.div_init_t1{
   font-size: 20pt;
   font-weight: 700;
}
.div_init_t2{
    margin-top: 20rpx;
    /* padding-left: 25%; */
    font-size: 12pt;
    font-weight: 500;
    text-align: center;
}
.el-main{
    overflow-y: auto;
    color: #000;
}
.row-h{
    min-height: 40px;
    margin-top: 20px;
}
.row-h-first{
    margin-top: 0;
}
.el-col-2{
    min-height: 40px;
    line-height: 40px;
}
.el-col-24{
    margin-top: 10px;
}
.el-col-title{
    font-weight: 600;
    margin-top: none;
}
.aiContent{
    padding: 10px;
    background: #f5f5f5;
    border-radius: 15px;
    /* line-height: 1.6; */
}
.col-content{
    line-height: 30px;
}
.col-rechat{
    text-align: right;
    margin-bottom: 15px;
}
</style>
