import { render, staticRenderFns } from "./mobileChat.vue?vue&type=template&id=7764f70f&scoped=true"
import script from "./mobileChat.vue?vue&type=script&lang=js"
export * from "./mobileChat.vue?vue&type=script&lang=js"
import style0 from "./mobileChat.vue?vue&type=style&index=0&id=7764f70f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7764f70f",
  null
  
)

export default component.exports