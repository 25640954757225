import Vue from 'vue'  
import Router from 'vue-router'
import index from '../components/index/index.vue'
import chatMain from '../components/chat/piChat.vue'
import onceChat from '../components/chat/onceChat.vue'
import pMess from '../components/message/pMess.vue'
import pYSMess from '../components/message/pYSMess.vue'
import taoCanPG from '../components/pack/taoCanPG.vue'
import userVeReg from '../components/user/userRegister.vue'
import chatShare from '@/components/chat/chatShare.vue'
import mobileChat from '@/components/chat/mobileChat.vue'
import mobileTaoCanPG from '@/components/pack/mobileTaoCanPG.vue'
import onlineMarket from '@/components/promote/onlineMarket.vue'
import mobileMarket from '@/components/promote/mobileMarket.vue'
import promoteCenter from '@/components/promote/promoteCenter.vue'
import indexAdm from '@/components/admin/indexAdm.vue'
import adminCenter from '@/components/admin/adminCenter.vue'
import userManage from '@/components/admin/userManage.vue'
import tixianOrder from '@/components/admin/tixianOrder.vue'
import taocanManage from '@/components/admin/taocanManage.vue'
import chongzhiOrder from '@/components/admin/chongzhiOrder.vue'
  
Vue.use(Router)

// const routes = [  
//   { path: '/', component: index },  
//   { path: '/chat', component: chat }  
// ];

export default new Router({  
    routes: [  
        { path: '/', component: index },  
        { path: '/chatMain/:topicId',name:'chatMain', component: chatMain, props: true },
        { path: '/mobileChat/:topicId',name:'mobileChat', component: mobileChat, props: true },
        { path: '/onceChat', component: onceChat },
        { path: '/pMess', component: pMess }, 
        { path: '/pYSMess', component: pYSMess },
        { path:'/taoCanPG',component:taoCanPG},
        { path:'/mobileTaoCanPG',component:mobileTaoCanPG},
        { path:'/veReg/:vTGMark?',name:'veReg',component:userVeReg,props: true},
        { path:'/chatShare/:vMarkG',name:'chatShare', component: chatShare, props: true },
        { path:'/onlineMarket/:vTGMark',name:'onlineMarket', component: onlineMarket, props: true },
        { path:'/mobileMarket/:vTGMark',name:'mobileMarket', component: mobileMarket, props: true },
        { path:'/promoteCenter/:vTGMark',name:'promoteCenter', component: promoteCenter, props: true },
        { path: '/admin',name:'indexAdm', component: indexAdm, props: true },  
        { path: '/admin/center',name:'adminCenter', component: adminCenter, props: true },  
        { path: '/admin/user',name:'userManage', component: userManage, props: true }, 
        { path: '/admin/taocan',name:'tancanManage', component: taocanManage, props: true }, 
        { path: '/admin/chongzhi',name:'chongzhiOrder', component: chongzhiOrder, props: true }, 
        { path: '/admin/tixian',name:'tixianOrder', component: tixianOrder, props: true }, 
      ]
  })