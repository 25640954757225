<template>
    <el-container height="100%">
        <el-header>
            <el-row>
                <el-col :span="4" class="header-1">启鹏Ai后台管理系统</el-col>
                <el-col :span="18">&nbsp;</el-col>
                <el-col :span="2" style="text-align:right;">
                    <el-menu class="el-menu-demo" mode="horizontal">
                        <el-menu-item index="3">
                            <i class="el-icon-switch-button"></i>退出</el-menu-item>
                    </el-menu>
                </el-col>
            </el-row>
        </el-header>
        <el-container>
            <el-aside>
                <el-menu
                    :default-active="activeIndex"
                    class="el-menu-vertical-demo"
                    background-color="#fafafa"
                    active-text-color="#409EFF" 
                    @select="handleSelect">
                    <el-menu-item index="1">
                        <i class="el-icon-menu"></i>
                        <span slot="title">工作台</span>
                    </el-menu-item>
                    <el-menu-item index="2">
                        <i class="el-icon-user-solid"></i>
                        <span slot="title">用户管理</span>
                    </el-menu-item>
                    <el-menu-item index="3">
                        <i class="el-icon-s-order"></i>
                        <span slot="title">充值订单</span>
                    </el-menu-item>
                    <el-menu-item index="4">
                        <i class="el-icon-s-order"></i>
                        <span slot="title">提现订单</span>
                    </el-menu-item>
                    <el-menu-item index="5">
                        <i class="el-icon-s-shop"></i>
                        <span slot="title">套餐管理</span>
                    </el-menu-item>
                </el-menu>
            </el-aside>
            <el-main>
                <component :is="currentComponent"></component>
            </el-main>
        </el-container>
        <el-footer>
            @启鹏Ai工作室
        </el-footer>
    </el-container>
</template>

<script>

import userManage from '@/components/admin/userManage.vue'
import tixianOrder from '@/components/admin/tixianOrder.vue'
import taocanManage from '@/components/admin/taocanManage.vue'
import chongzhiOrder from '@/components/admin/chongzhiOrder.vue'
import etCard from '@/components/admin/etCard.vue'

// import cookieH from 'js-cookie'
// import axios from 'axios'
// import $conf from '@/apiconfig'

    export default {
        name: 'adminCenter',
        props:{
            vTGMark: {
                type: String,
                default: "",
            }
        },
        components: {
            userManage,
            tixianOrder,
            taocanManage,
            chongzhiOrder,
            etCard
        },
        data()
        {
            return {
                activeIndex:"1",
                currentComponent:'etCard',
                pagesRoute:[
                    {index:"1",page:'etCard'},
                    {index:"2",page:'userManage'},
                    {index:"3",page:'chongzhiOrder'},
                    {index:"4",page:'tixianOrder'},
                    {index:"5",page:'taocanManage'}
                ]
            };
        }, 
        mounted(){
            this.init();
        },  
        computed: {  
            
        },
        methods:{
            init(){
                
            },
            handleSelect(index){
                console.log(index)
                this.activeIndex = index;  
                const page = this.pagesRoute.filter(item => item.index===index)[0]?.page; // 简单转换，假设组件名以Page结尾 
                this.currentComponent=page; 
                console.log(this.currentComponent);
            },
                
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-container{
    height: 100%;
    overflow: hidden;
    color: #000;

}
.el-footer {
    background-color: #FFF;
    color: #000;
    text-align: center;
    height: 60px;
    line-height: 60px;
    border-top: 1px solid #e6e6e6;
}
.el-main{
    overflow-y: auto;
    color: #000;
}
.el-header {
    background-color: #FFF;
    color: #333;
    text-align: center;
    line-height: 60px;
    border-bottom: 1px solid #e6e6e6;
  }
.header-1{
    color: #409EFF;
    font-size: x-large;
    font-weight: 700;
}
.el-aside {
    background-color: #fafafa;
    color: #333;
    text-align: center;
    width:200px;
}
.el-menu-vertical-demo{
    height: 100%;
    text-align: left;
}
  
</style>
