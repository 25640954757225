<template>
    <el-container height="100%">
        <el-main>
            <el-tabs tab-position="left" style="height: 100%;" @tab-click="handleClick">
                <el-tab-pane label="基本信息" name="1">
                        <div ref="div_scroll" style="overflow-y: auto; height:100%;">
                            <el-row class="row-h row-h-first">
                                <el-col :span="10" style="text-align: center;">
                                    <el-card class="box-card">
                                        <el-row class="row-h row-h-first">
                                            <el-col :span="12" style="text-align: center;">
                                                <div ref="qrcode" style="width: 200px; height: 200px;"></div>
                                            </el-col>
                                            <el-col :span="12" style="text-align: center;">
                                                <div style="height: 100px; line-height: 150px; font-size: large; font-weight: 600;">用手机扫码注册</div>
                                                <div style="height: 100px; line-height: 30px;">微信 或 手机浏览器</div>
                                            </el-col>
                                        </el-row>
                                        <el-row class="row-h row-h-first">
                                            <el-col :span="24" style="text-align: center;">
                                                <el-input 
                                                    type="text" 
                                                    v-model="qrCodeUrl" disabled>
                                                    <div slot="append">
                                                        <el-button icon="el-icon-copy-document" @click="copyLink">复制链接</el-button>
                                                    </div>
                                                    </el-input>
                                            </el-col>
                                        </el-row>
                                    </el-card>
                                </el-col>
                                <el-col :span="2"></el-col>
                                <el-col :span="10" style="text-align: center;">
                                    <!-- <el-card class="box-card">
                                    </el-card> -->
                                </el-col>
                            </el-row>
                        </div>
                </el-tab-pane>
                <el-tab-pane label="提现申请" name="2">
                    <div style="margin-bottom: 20px; text-align: right;">
                        <div v-if="okSelectCount>0" style="display: block; float:left; line-height:40px; padding-left: 55px;">已选择 <el-tag>{{okSelectCount}}</el-tag> 笔订单，总提成金额约 ￥ <el-tag type="danger">{{okSelectMoney.toFixed(2)}}</el-tag> 元</div>
                        <el-button @click="toApply" type="primary">提现申请</el-button>
                    </div>
                    <el-table
                        ref="OrderTable"
                        :data="orderList.data"
                        tooltip-effect="dark"
                        border
                        stripe
                        height="600"
                        @selection-change="handleSelectionChange">
                        <el-table-column
                            type="selection"
                            width="40"
                            :selectable="selectableFunc"
                            >
                        </el-table-column>
                        <el-table-column
                            v-if="isShowIdColumn"
                            prop="id"
                            label="订单ID"
                            >
                        </el-table-column>
                        <el-table-column
                            prop="tradeNo"
                            label="订单编号"
                            width="200">
                        </el-table-column>
                        <el-table-column
                            label="订单金额"
                            prop="orderMoney">
                        </el-table-column>
                        <el-table-column
                            prop="createTime"
                            label="订单日期">
                        </el-table-column>
                        <el-table-column
                            prop="tradeTime"
                            label="支付日期">
                        </el-table-column>
                        <el-table-column
                            prop="tradeType"
                            label="支付方式">
                            <template slot-scope="scope">
                                <el-tag
                                :type="scope.row.tradeType === '支付宝' ? 'primary' : 'success'"
                                disable-transitions>{{scope.row.tradeType}}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="tradeStatus"
                            label="支付状态">
                            <template slot-scope="scope">
                                <el-tag
                                :type="scope.row.tradeStatus === '成功' ? 'success' : 'info'"
                                disable-transitions>{{scope.row.tradeStatus}}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="nickName"
                            label="用户昵称"
                            show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column
                            prop="tcName"
                            label="套餐名称"
                            show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column
                            prop="tcLV"
                            label="提成点数"
                            show-overflow-tooltip>
                            <template slot-scope="scope">{{ scope.row.tcLV*100 }}%</template>
                        </el-table-column>
                        <el-table-column
                            prop="tcMoney"
                            label="可提成金额"
                            show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column
                            prop="tcStatus"
                            label="提成状态"
                            show-overflow-tooltip>
                            <template slot-scope="scope">
                                <el-tag
                                :type="scope.row.tcStatus === 0 ? 'success' : 'danger'"
                                disable-transitions>{{scope.row.tcStatus===0?'未锁定结算':'锁定结算中'}}</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :total="orderList.count"
                        :page-size="pageSize"
                        @current-change="initMarketList">
                    </el-pagination>
                </el-tab-pane>
                <el-tab-pane label="提现订单" name="3">
                    <el-table
                        ref="tcorderTable"
                        :data="tcOrderList.data"
                        tooltip-effect="dark"
                        border
                        stripe
                        height="600">
                        <el-table-column
                            v-if="isShowIdColumn"
                            prop="id"
                            label="订单ID"
                            >
                        </el-table-column>
                        <el-table-column
                            prop="tcNo"
                            label="订单编号"
                            width="200">
                        </el-table-column>
                        <el-table-column
                            label="提成金额"
                            prop="tcMoney">
                        </el-table-column>
                        <el-table-column
                            prop="applyTime"
                            label="申请日期">
                        </el-table-column>
                        <el-table-column
                            prop="checkTime"
                            label="审核日期">
                        </el-table-column>
                        <el-table-column
                            prop="acceptTime"
                            label="结算日期">
                        </el-table-column>
                        <el-table-column
                            prop="tcRemark"
                            label="说明"
                            show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column
                            prop="statusName"
                            label="订单状态">
                            <template slot-scope="scope">
                                <el-tag
                                :type="scope.row.status === 0 ? '' : 
                                scope.row.status === 1 ? 'warning' : 
                                scope.row.status === 2 ? 'success' : 
                                scope.row.status === 3 ? 'danger' : 
                                scope.row.status === 4 ? 'danger' : 'info'"
                                disable-transitions>{{scope.row.statusName}}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-if="isShowIdColumn"
                            prop="linkOrder"
                            label="关联订单"
                            show-overflow-tooltip>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :total="tcOrderList.count"
                        :page-size="pageSize"
                        @current-change="initTGOrderList">
                    </el-pagination>
                </el-tab-pane>
            </el-tabs>
            
        </el-main>
    </el-container>
</template>

<script>
import cookieH from 'js-cookie'
import axios from 'axios'
import $conf from '@/apiconfig'
import QRCode from 'qrcode'

    export default {
        name: 'promoteCenter',
        props:{
            vTGMark: {
                type: String,
                default: "",
            }
        },
        components: {
        },
        data()
        {
            return {
                content:"",
                orderList:[],
                multipleSelection:[],
                pageSize:40,
                okSelectCount:0,
                okSelectMoney:0,
                okSelectIds:[],
                isShowIdColumn:false,
                // eventSource:null,
                messCont:'',
                isDisabledButton:false,
                tcOrderList:[],
                you:"",
                qrCodeUrl:"https://qipengai.com"+"/#/veReg/"+this.vTGMark
            };
        }, 
        watch:{
            vTGMark(){
                // this.$message.info("init     "  + this.tid)
                if(this.vTGMark!=""){
                    this.init();
                }
            },
        },
        mounted(){
            // console.log(this.vMarkG+"   ||   "+this.typ)
            if(this.vTGMark!=""){
                this.init();
                this.initMarketList(1);
                this.initTGOrderList(1);
            }
        },  
        computed: {  
            iconClass() {  
                return this.isDisabledButton ? 'el-icon-loading' : 'el-icon-s-promotion';  
            },  
        },
        methods:{
            init(){
                this.initMarket()
            },
            async initMarket(){
                
                this.qrCodeUrl=window.location.origin+'/#/veReg/'+this.vTGMark
                // 使用 qrcode 的 toDataURL 方法生成二维码图片数据  
                QRCode.toDataURL(this.qrCodeUrl, {  
                        width: 200, // 宽度  
                        height: 200, // 高度  
                        color: {  
                            dark: '#000000', // 前景色（二维码颜色）  
                            light: '#ffffff' // 背景色  
                        }  
                    }, function (err, url) {  
                        if (err) {  
                            console.error(err);  
                            return;  
                        }  
                        
                        // 使用生成的二维码图片数据来显示二维码  
                        // 这里我们假设你有一个 img 元素，你可以通过 ref 或其他方式获取它  
                        // 但是 qrcode.toDataURL 返回的是 base64 编码的图片 URL，可以直接设置为 img 的 src  
                        // 但由于我们直接在 div 中展示，需要稍微变通一下  
                
                        // 创建一个 img 元素并设置其 src 属性为生成的 URL  
                        const img = new Image();  
                        img.src = url;  
                
                        // 将 img 元素添加到我们的 qrcode div 中  
                        // 这里为了简单起见，我们直接设置 qrcode div 的背景图片  
                        // 注意：这只是一个示例，实际上你可能需要更复杂的 DOM 操作或使用其他库来展示图片  
                        this.$refs.qrcode.style.backgroundImage = `url(${url})`;  
                        this.$refs.qrcode.style.backgroundSize = 'cover';  
                
                        // 如果你想要将图片插入到 DOM 中，而不是作为背景，你可能需要创建一个 img 元素并添加到 DOM 中  
                        // 例如：  
                        // this.$refs.qrcode.appendChild(img);  
                    }.bind(this)); // 注意这里使用 bind(this) 来确保回调函数中的 this 指向 Vue 实例  

            },
            async initMarketList(pageIndex){
                try {
                    pageIndex = (pageIndex==undefined || pageIndex == 0) ? 1 : pageIndex
                    const loginUrl=$conf.dev.apiUrl+'tgCenter/query/list';
                    const response = await axios.post(loginUrl, {
                        father:this.vTGMark,
                        pageSize:this.pageSize,
                        pageIndex:pageIndex
                    },{
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        resD.data.forEach(order => {
                            order.createTime = $conf.common.formattedDate(order.createTime);
                            order.tradeTime = $conf.common.formattedDate(order.tradeTime);
                            order.tradeType=order.tradeType==1?'微信':order.tradeType==2?'支付宝':'未知';
                            order.tradeStatus=order.tradeStatus==1?'成功':order.tradeStatus==2?'失败':'未支付';
                            order.isSelect = order.tcStatus==0;
                        });
                        this.orderList=resD;
                    }else{
                        this.$message.error(resD.msg);  
                    }
                } catch (error) {  
                    this.$message.error(error);  
                } 
            },
            handleClick(tab, event){
                console.log(tab,event);
                if(tab.name==2){
                    this.initMarketList(1)
                }
                else if(tab.name==3){
                    this.initTGOrderList(1)
                }
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.okSelectCount = this.multipleSelection.length;
                this.okSelectMoney=0;
                this.okSelectIds=[];
                this.multipleSelection.forEach(item => {
                    this.okSelectMoney+=item.tcMoney
                    this.okSelectIds.push(item.id)
                })
                console.log(this.okSelectIds);
            },
            selectableFunc(row){
                // console.log(index);
                return row.isSelect
            },
            async toApply(){
                let vm=this;
                if(this.okSelectCount<1 || this.okSelectMoney<=0){
                    this.$notify.error({
                        title: '错误',
                        message: '请选择提现订单'
                    });
                    return false;
                }
                const token = cookieH.get("userTK");
                if(token == "" || token == undefined){
                    this.$message.error({
                        message: '您还没有登陆，请先登录',
                        onClose:function(){
                            vm.$router.push('/');
                        }
                    });
                    return false;
                }

                try {
                    const url=$conf.dev.apiUrl+'tgCenter/apply';
                    const response = await axios.post(url, {
                        token:token,
                        count:this.okSelectCount,
                        money:this.okSelectMoney,
                        ids:this.okSelectIds
                    },{
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        this.$message.success({
                            message:'申请成功，等待审核……',
                            onClose:function(){
                                vm.initMarketList(1);
                            }
                        });  
                    }else{
                        this.$message.error(resD.msg);  
                    }
                } catch (error) {  
                    this.$message.error(error);  
                } 
            },
            async initTGOrderList(pageIndex){
                try {
                    let vm = this
                    const token = cookieH.get("userTK");
                    if(token == "" || token == undefined){
                        this.$message.error({
                            message: '您还没有登陆，请先登录',
                            onClose:function(){
                                vm.$router.push('/');
                            }
                        });
                        return false;
                    }

                    pageIndex = (pageIndex==undefined || pageIndex == 0) ? 1 : pageIndex
                    const loginUrl=$conf.dev.apiUrl+'tgCenter/query/tcorderlist';
                    const response = await axios.post(loginUrl, {
                        token:token,
                        pageSize:this.pageSize,
                        pageIndex:pageIndex
                    },{
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        resD.data.forEach(order => {
                            order.applyTime = $conf.common.formattedDate(order.applyTime);
                            order.checkTime = $conf.common.formattedDate(order.checkTime);
                            order.acceptTime = $conf.common.formattedDate(order.acceptTime);
                            order.statusName=order.status==0?'待审核':
                                        order.status==1?'未结算':
                                        order.status==2?'已结算':
                                        order.status==3?'审核失败':
                                        order.status==4?'结算失败':'未知';
                        });
                        this.tcOrderList=resD;
                    }else{
                        this.$message.error(resD.msg);  
                    }
                } catch (error) {  
                    this.$message.error(error);  
                } 
            },
            async copyLink(){
                try {
                    const shareLinText="叮！点击链接 "+this.qrCodeUrl+" -- 注册帐号，一起开启和启鹏AI的奇妙之旅吧~ \n启鹏Ai，既能写文案、想点子，又能陪你聊天，答疑解惑，是你工作学习生活的智能伙伴！"
                    await navigator.clipboard.writeText(shareLinText);  
                    this.$message.success("链接复制成功。");  
                } catch (err) {
                    console.error(err);  
                    this.$message.info("链接复制失败。");  
                }  

            },
                
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-container{
height: 100%;
overflow: hidden;
    color: #000;

}
.el-footer {
    background-color: #ffffff;
    color: #000;
    text-align: center;
    height: 120px;
    border-top: 1px solid #f4f4f4;
}
.div_init{
   margin-top: 30vh;
}
.div_init_t1{
   font-size: 20pt;
   font-weight: 700;
}
.div_init_t2{
    margin-top: 10px;
    /* padding-left: 25%; */
    font-size: 12pt;
    font-weight: 500;
    text-align: center;
}
.el-main{
    overflow-y: auto;
    color: #000;
}
.row-h{
    min-height: 40px;
    margin-top: 20px;
}
.row-h-first{
    margin-top: 0;
}
.el-col-2{
    min-height: 40px;
    line-height: 40px;
}
.el-col-24{
    margin-top: 10px;
}
.el-col-title{
    font-weight: 600;
    margin-top: none;
}
.aiContent{
    padding: 10px;
    background: #f5f5f5;
    border-radius: 15px;
    /* line-height: 1.6; */
}
.col-content{
    line-height: 30px;
}
.col-rechat{
    text-align: right;
    margin-bottom: 15px;
}
</style>
